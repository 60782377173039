import PropTypes from "prop-types";
import React, { useState } from "react";
import { Link } from "gatsby";

import {
  HeaderNavigation as BaseHeaderNavigation,
  StyledNavigationList,
} from "baseui/header-navigation";
import { useStyletron } from "baseui";

import logo from "../../assets/images/logo.svg";
import logoWhiteText from "../../assets/images/logo-white-text.svg";
import logoText from "../../assets/images/logo-text.svg";
import logoShrink from "../../assets/images/logo-shrink.svg";

import { useTheme } from "../../contexts/theme";

export const HeaderNavigation = ({ children, $style, ...props }) => {
  const [, theme] = useStyletron();

  return (
    <BaseHeaderNavigation
      data-csweb="header-navigation"
      overrides={{
        Root: {
          style: {
            height: "100%",
            paddingTop: "60px",
            paddingBottom: "60px",
            borderBottomWidth: "0px",
            ...$style,
            [theme.breakpoints.mediaQueries.medium]: {
              paddingTop: "30px",
              paddingBottom: "30px",
            },
          },
        },
      }}
      {...props}
    >
      {children}
    </BaseHeaderNavigation>
  );
};

HeaderNavigation.propTypes = {
  children: PropTypes.node.isRequired,
  $style: PropTypes.object,
};

HeaderNavigation.defaultProps = {
  children: ``,
  $style: {},
};

export const HeaderNavigationList = ({
  showOnMobile,
  children,
  $as,
  $style,
  ...props
}) => {
  const [, theme] = useStyletron();

  return (
    <StyledNavigationList
      $as={$as}
      data-csweb="header-navigation-list"
      $style={{
        minHeight: "39px",
        paddingLeft: "0px",
        paddingRight: "0px",
        ...(!showOnMobile && {
          [theme.breakpoints.mediaQueries.medium]: {
            display: "none",
          },
        }),
        ...(showOnMobile && {
          [theme.breakpoints.mediaQueries.medium]: {
            alignSelf: "flex-start",
          },
        }),
        ...$style,
      }}
      {...props}
    >
      {children}
    </StyledNavigationList>
  );
};

HeaderNavigationList.propTypes = {
  showOnMobile: PropTypes.bool,
  $style: PropTypes.object,
  $as: PropTypes.string,
  children: PropTypes.node.isRequired,
};

HeaderNavigationList.defaultProps = {
  showOnMobile: false,
  $style: {},
  $as: ``,
  children: ``,
};

export const HeaderNavigationBrand = ({ color, shrink }) => {
  const [css] = useStyletron();
  const { useDarkTheme } = useTheme();

  return (
    <Link
      to="/"
      data-csweb="header-navigation-brand"
      className={css({
        alignSelf: "center",
        position: "relative",
        overflow: "hidden",
        marginTop: "0px",
        marginRight: "0px",
        marginBottom: "0px",
        marginLeft: "0px",
        padding: "0px",
        boxSizing: "border-box",
        lineHeight: "0.1",
        cursor: "pointer",
        ...(!shrink && {
          marginTop: "-14px",
          "::before": {
            content: "''",
            background:
              "linear-gradient(135deg, rgba(60,0,220,1) 0%, rgba(0,220,230,1) 100%)",
            left: "-100%",
            width: "100%",
            top: "0",
            right: "0",
            bottom: "0",
            position: "absolute",
            transitionProperty: "all",
            transitionDuration: "300ms",
            transitionTimingFunction: "ease-in-out",
            zIndex: "10",
          },
          ":hover::before": {
            left: "0%",
          },
          ":hover img": {
            opacity: "0",
          },
          "::after": {
            content: "''",
            backgroundImage: `url(${logoText})`,
            backgroundSize: "100% auto",
            backgroundPosition: "left 0px",
            backgroundRepeat: "no-repeat",
            left: "6px",
            width: "70px",
            right: "0",
            height: "12px",
            bottom: "-12px",
            position: "absolute",
            transitionProperty: "all",
            transitionDuration: "300ms",
            transitionTimingFunction: "ease-in-out",
            zIndex: "10",
          },
          ":hover::after": {
            bottom: "6px",
          },
        }),
      })}
    >
      {shrink ? (
        <img
          alt="Codeshine"
          className={css({
            maxWidth: "220px",
            maxHeight: "28px",
            transitionProperty: "all",
            transitionDuration: "200ms",
            transitionTimingFunction: "ease-in-out",
          })}
          src={logoShrink}
        />
      ) : (
        <img
          alt="Codeshine"
          className={css({
            maxWidth: "220px",
            height: "26px",
            transitionProperty: "all",
            transitionDuration: "200ms",
            transitionTimingFunction: "ease-in-out",
          })}
          src={useDarkTheme || color === "white" ? logoWhiteText : logo}
        />
      )}
    </Link>
  );
};

HeaderNavigationBrand.propTypes = {
  color: PropTypes.string,
  shrink: PropTypes.bool,
};

HeaderNavigationBrand.defaultProps = {
  color: ``,
  shrink: false,
};

export const HeaderNavigationItem = ({
  children,
  color,
  to,
  $style,
  ...props
}) => {
  const [css, theme] = useStyletron();
  const [isActive, setIsActive] = useState(false);

  return (
    <li
      data-csweb="header-navigation-item"
      className={css({
        whiteSpace: "nowrap",
        fontWeight: "600",
        alignSelf: "center",
        fontSize: "14px",
        height: "28px",
        position: "relative",
        overflow: "hidden",
        marginLeft: theme.sizing.scale1000,
        "::after": {
          content: "''",
          display: "block",
          position: "absolute",
          bottom: "0",
          left: isActive ? "0px" : "-105%",
          right: "0",
          width: "100%",
          height: "2px",
          background:
            "linear-gradient(90deg, rgba(60,0,220,1) 0%, rgba(0,220,230,1) 100%)",
          transitionProperty: "all",
          transitionDuration: "100ms",
          transitionDelay: "200ms",
          transitionTimingFunction: "ease-in-out",
        },
        ":hover::after": {
          left: "0",
        },
        ":hover": {
          cursor: "pointer",
        },
        ...$style,
        [theme.breakpoints.mediaQueries.medium]: {
          fontSize: "20px",
          height: "40px",
        },
      })}
      {...props}
    >
      <Link
        to={to}
        getProps={({ isPartiallyCurrent }) => setIsActive(isPartiallyCurrent)}
        className={css({
          transform: isActive ? "translateY(0px)" : "translateY(-28px)",
          lineHeight: "2",
          textDecoration: "none",
          color: color || theme.colors.headerNavigationItemColor,
          display: "block",
          transitionProperty: "all",
          transitionDuration: "300ms",
          transitionTimingFunction: "ease-in-out",
          ":hover": {
            transform: "translateY(0px)",
          },

          [theme.breakpoints.mediaQueries.medium]: {
            transform: isActive ? "translateY(0px)" : "translateY(-40px)",
          },
        })}
      >
        <span className={css({ display: "block" })}>{children}</span>
        <span className={css({ display: "block" })}>{children}</span>
      </Link>
    </li>
  );
};

HeaderNavigationItem.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.string,
  to: PropTypes.string,
  $style: PropTypes.object,
};

HeaderNavigationItem.defaultProps = {
  children: ``,
  color: ``,
  to: ``,
  $style: {},
};

export const HeaderNavigationButton = ({ children, ...props }) => {
  const [css, theme] = useStyletron();
  return (
    <li
      data-csweb="header-navigation-button"
      className={css({
        alignSelf: "center",
        marginLeft: theme.sizing.scale1000,
      })}
      {...props}
    >
      {children}
    </li>
  );
};

HeaderNavigationButton.propTypes = {
  children: PropTypes.node.isRequired,
};

HeaderNavigationButton.defaultProps = {
  children: ``,
};

export const HeaderNavigationLangMenu = ({ color }) => {
  const [css, theme] = useStyletron();

  return (
    <ul
      data-csweb="header-navigation-lang-menu"
      className={css({
        width: "38px",
        height: "38px",
        overflow: "hidden",
        borderTopLeftRadius: "50px",
        borderTopRightRadius: "50px",
        borderBottomRightRadius: "50px",
        borderBottomLeftRadius: "50px",
        boxSizing: "border-box",
        padding: "0px",
        position: "relative",
        ":hover li[data-active=true]": {
          left: "-38px",
        },
        ":hover li[data-active=false] span": {
          opacity: "1",
          transform: "scale(1)",
        },
      })}
    >
      {[
        { lang: "PL", active: false },
        { lang: "EN", active: true },
      ].map((item, index) => (
        <li
          data-active={item.active}
          className={css({
            position: "absolute",
            left: "0",
            top: "0",
            width: "38px",
            height: "38px",
            fontSize: "14px",
            overflow: "hidden",
            borderTopLeftRadius: "50px",
            borderTopRightRadius: "50px",
            borderBottomRightRadius: "50px",
            borderBottomLeftRadius: "50px",
            boxSizing: "border-box",
            listStyle: "none",
            cursor: "pointer",
            background:
              color === "white"
                ? "unset"
                : !item.active
                ? theme.colors.langSwitchFill
                : theme.colors.langSwitchActiveFill,
            borderTopWidth: "2px",
            borderRightWidth: "2px",
            borderBottomWidth: "2px",
            borderLeftWidth: "2px",
            borderTopStyle: "solid",
            borderRightStyle: "solid",
            borderBottomStyle: "solid",
            borderLeftStyle: "solid",
            borderColor: color
              ? color
              : !item.active
              ? theme.colors.langSwitchBorder
              : theme.colors.langSwitchActiveBorder,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            lineHeight: "1",
            color:
              color === "white"
                ? !item.active
                  ? theme.colors.langSwitchText
                  : color
                : !item.active
                ? theme.colors.langSwitchText
                : theme.colors.langSwitchActiveText,
            fontWeight: "600",
            transitionProperty: "all",
            transitionDuration: "300ms",
            transitionTimingFunction: "ease-in-out",
          })}
          key={index}
        >
          <span
            className={css({
              opacity: item.active ? "1" : "0",
              transform: item.active ? "scale(1)" : "scale(0)",
              transitionProperty: "all",
              transitionDuration: "200ms",
              transitionDelay: "100ms",
              transitionTimingFunction: "ease-in-out",
            })}
          >
            {item.lang}
          </span>
        </li>
      ))}
    </ul>
  );
};

HeaderNavigationLangMenu.propTypes = {
  color: PropTypes.string,
};

HeaderNavigationLangMenu.defaultProps = {
  color: ``,
};
